var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "components-container" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              _vm.dialogTableVisible = true
            },
          },
        },
        [_vm._v(" open a Drag Dialog ")]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "el-drag-dialog", rawName: "v-el-drag-dialog" }],
          attrs: { visible: _vm.dialogTableVisible, title: "Shipping address" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
            dragDialog: _vm.handleDrag,
          },
        },
        [
          _c(
            "el-select",
            {
              ref: "select",
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.gridData } },
            [
              _c("el-table-column", {
                attrs: { property: "date", label: "Date", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { property: "name", label: "Name", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { property: "address", label: "Address" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }