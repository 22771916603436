var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "dragTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "row-key": "id",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "65" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.id))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "180px", align: "center", label: "Date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            row.timestamp,
                            "{y}-{m}-{d} {h}:{i}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "300px", label: "Title" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.title))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "110px", align: "center", label: "Author" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.author))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "100px", label: "Importance" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(+row.importance, function (n) {
                    return _c("svg-icon", {
                      key: n,
                      staticClass: "icon-star",
                      attrs: { "icon-class": "star" },
                    })
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Readings", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.pageviews))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "status-col",
              label: "Status",
              width: "110",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: _vm._f("statusFilter")(row.status) } },
                      [_vm._v(" " + _vm._s(row.status) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Drag", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  return [
                    _c("svg-icon", {
                      staticClass: "drag-handler",
                      attrs: { "icon-class": "drag" },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "show-d" },
        [
          _c("el-tag", [_vm._v("The default order :")]),
          _vm._v(" " + _vm._s(_vm.oldList) + " "),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "show-d" },
        [
          _c("el-tag", [_vm._v("The after dragging order :")]),
          _vm._v(" " + _vm._s(_vm.newList) + " "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }