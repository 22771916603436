var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.formThead,
                callback: function ($$v) {
                  _vm.formThead = $$v
                },
                expression: "formThead",
              },
            },
            [
              _c("el-checkbox", { attrs: { label: "apple" } }, [
                _vm._v(" apple "),
              ]),
              _c("el-checkbox", { attrs: { label: "banana" } }, [
                _vm._v(" banana "),
              ]),
              _c("el-checkbox", { attrs: { label: "orange" } }, [
                _vm._v(" orange "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "name", label: "fruitName", width: "180" },
          }),
          _vm._l(_vm.formThead, function (fruit) {
            return _c("el-table-column", {
              key: fruit,
              attrs: { label: fruit },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row[fruit]) + " ")]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }