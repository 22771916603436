var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticStyle: { width: "100%", "padding-top": "15px" },
      attrs: {
        data:
          _vm.select_class_range == 0
            ? _vm.get_now_reserve_type_0
            : _vm.get_now_reserve_type_1,
      },
    },
    [
      _c("el-table-column", {
        attrs: { label: "治療師/講師" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("el-tag", [
                  _vm._v(
                    _vm._s(scope.row.reserve_system_shop_basic_schedule_teacher)
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.select_class_range == 0,
            expression: "select_class_range==0",
          },
        ],
        attrs: { label: "課程名稱" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      scope.row.reserve_system_shop_basic_schedule_class_name
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "開始日期" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      scope.row.reserve_system_shop_basic_schedule_start_date
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "開始時間", align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      scope.row.reserve_system_shop_basic_schedule_start_time
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "結束時間", align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      scope.row.reserve_system_shop_basic_schedule_end_time
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { align: "center", label: "實際預約人數" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("el-tag", { attrs: { type: "primary", plain: "" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        scope.row
                          .reserve_system_shop_basic_schedule_practice_people
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "排程狀態", width: "100", align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type:
                        scope.row.reserve_system_shop_basic_schedule_status ==
                          1 ||
                        scope.row.reserve_system_shop_basic_schedule_status == 2
                          ? "danger"
                          : "primary",
                      plain: "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("reserve_system_shop_basic_schedule_status")(
                            scope.row.reserve_system_shop_basic_schedule_status
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }