var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "main-article",
      attrs: { "element-loading-text": "正在產生收據pdf" },
    },
    [
      _c("div", [
        _c("div", { staticClass: "article__heading" }, [
          _c(
            "div",
            {
              staticClass: "article__heading__title",
              staticStyle: {
                margin: "10px 0",
                "font-size": "15px",
                "text-align": "center",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.unitList[0]["reserve_system_unit_name"]) + " "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "article__heading__title",
              staticStyle: {
                margin: "10px 0",
                "font-size": "15px",
                "text-align": "center",
              },
            },
            [_vm._v(" 收據 ")]
          ),
          _c(
            "div",
            {
              staticClass: "article__heading__title",
              staticStyle: {
                color: "red",
                "font-size": "15px",
                "text-align": "right",
              },
            },
            [_vm._v(" 第一聯 單位留存聯 ")]
          ),
          _c(
            "div",
            {
              staticClass: "article__heading__title",
              staticStyle: {
                margin: "10px 0",
                "font-size": "15px",
                "text-align": "right",
              },
            },
            [
              _vm._v(
                " 單據序號：" +
                  _vm._s(_vm.reception_List[0]["reserve_system_reception_id"]) +
                  " "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "user-profile" }, [
          _c("table", [
            _c(
              "tbody",
              [
                _c("tr", [
                  _c("td", { attrs: { width: "200" } }, [
                    _vm._v(
                      " 姓名：" +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_member_username"
                          ]
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { attrs: { width: "200" } }, [
                    _vm._v(
                      " 出生年月日：" +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_member_birthday"
                          ]
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { attrs: { colspan: "3", width: "348" } }, [
                    _vm._v(
                      " 費用期間： " +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_shop_basic_schedule_start_date"
                          ]
                        ) +
                        " " +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_shop_basic_schedule_start_time"
                          ]
                        ) +
                        " ~ " +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_shop_basic_schedule_end_time"
                          ]
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _vm._m(0),
                _vm._l(
                  _vm.reception_List[0]["get_reception_detail"],
                  function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", { attrs: { colspan: "3", width: "538" } }, [
                        _vm._v(
                          " " +
                            _vm._s(item.reserve_system_reception_item_name) +
                            " "
                        ),
                      ]),
                      _c("td", { attrs: { width: "158", align: "center" } }, [
                        _vm._v(
                          " " +
                            _vm._s(item.reserve_system_reception_item_price) +
                            " "
                        ),
                      ]),
                    ])
                  }
                ),
                _c("tr", [
                  _vm._m(1),
                  _c("td", { attrs: { width: "158", align: "center" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.reception_List[0]["reserve_system_amount_payable"]
                      )
                    ),
                  ]),
                ]),
                _c("tr", [
                  _vm._m(2),
                  _c("td", { attrs: { width: "158", align: "center" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_amount_practice"
                          ]
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("tr", [
                  _vm._m(3),
                  _c("td", { attrs: { width: "158", align: "center" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.reception_List[0]["reserve_system_amount_owed"]
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2", width: "350" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.unitList[0]["reserve_system_unit_name"])
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "地址：" +
                          _vm._s(_vm.unitList[0]["reserve_system_unit_tax"])
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "電話：" +
                          _vm._s(_vm.unitList[0]["reserve_system_unit_phone"])
                      ),
                    ]),
                    _c("p", [_vm._v("收訖章：")]),
                  ]),
                  _c("td", { attrs: { colspan: "2", width: "347" } }, [
                    _c("p", [_vm._v("收款資訊：")]),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.reception_List[0][
                                "reserve_system_pay_people"
                              ] == "",
                            expression:
                              "reception_List[0]['reserve_system_pay_people']==''",
                          },
                        ],
                      },
                      [_vm._v("□現金   繳款人：_______________")]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.reception_List[0][
                                "reserve_system_pay_people"
                              ] != "",
                            expression:
                              "reception_List[0]['reserve_system_pay_people']!=''",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "□現金   繳款人：" +
                            _vm._s(
                              _vm.reception_List[0]["reserve_system_pay_people"]
                            )
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.reception_List[0][
                                "reserve_system_pay_people"
                              ] == "",
                            expression:
                              "reception_List[0]['reserve_system_pay_people']==''",
                          },
                        ],
                      },
                      [_vm._v("□匯款   繳款人：_______________")]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.reception_List[0][
                                "reserve_system_pay_people"
                              ] != "",
                            expression:
                              "reception_List[0]['reserve_system_pay_people']!=''",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "□匯款   繳款人：" +
                            _vm._s(
                              _vm.reception_List[0]["reserve_system_pay_people"]
                            )
                        ),
                      ]
                    ),
                    _c("p", [_vm._v("帳號資訊：_________________")]),
                  ]),
                ]),
              ],
              2
            ),
          ]),
        ]),
        _c("div", { staticStyle: { margin: "10px 0", color: "red" } }, [
          _vm._v(" 本憑證請妥為保管，遺失數不補發 "),
        ]),
      ]),
      _c("br"),
      _c("br"),
      _c("div", { staticStyle: { margin: "5px 0" } }, [
        _c("div", { staticClass: "article__heading" }, [
          _c(
            "div",
            {
              staticClass: "article__heading__title",
              staticStyle: {
                margin: "1px 0",
                "font-size": "15px",
                "text-align": "center",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.unitList[0]["reserve_system_unit_name"]) + " "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "article__heading__title",
              staticStyle: {
                margin: "10px 0",
                "font-size": "15px",
                "text-align": "center",
              },
            },
            [_vm._v(" 收據 ")]
          ),
          _c(
            "div",
            {
              staticClass: "article__heading__title",
              staticStyle: {
                color: "red",
                "font-size": "15px",
                "text-align": "right",
              },
            },
            [_vm._v(" 第二聯 客戶留存聯 ")]
          ),
          _c(
            "div",
            {
              staticClass: "article__heading__title",
              staticStyle: {
                margin: "10px 0",
                "font-size": "15px",
                "text-align": "right",
              },
            },
            [
              _vm._v(
                " 單據序號：" +
                  _vm._s(_vm.reception_List[0]["reserve_system_reception_id"]) +
                  " "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "user-profile" }, [
          _c("table", [
            _c(
              "tbody",
              [
                _c("tr", [
                  _c("td", { attrs: { width: "200" } }, [
                    _vm._v(
                      " 姓名：" +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_member_username"
                          ]
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { attrs: { width: "200" } }, [
                    _vm._v(
                      " 出生年月日：" +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_member_birthday"
                          ]
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { attrs: { colspan: "3", width: "348" } }, [
                    _vm._v(
                      " 費用期間： " +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_shop_basic_schedule_start_date"
                          ]
                        ) +
                        " " +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_shop_basic_schedule_start_time"
                          ]
                        ) +
                        " ~ " +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_shop_basic_schedule_end_time"
                          ]
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _vm._m(4),
                _vm._l(
                  _vm.reception_List[0]["get_reception_detail"],
                  function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", { attrs: { colspan: "3", width: "538" } }, [
                        _vm._v(
                          " " +
                            _vm._s(item.reserve_system_reception_item_name) +
                            " "
                        ),
                      ]),
                      _c("td", { attrs: { width: "158", align: "center" } }, [
                        _vm._v(
                          " " +
                            _vm._s(item.reserve_system_reception_item_price) +
                            " "
                        ),
                      ]),
                    ])
                  }
                ),
                _c("tr", [
                  _vm._m(5),
                  _c("td", { attrs: { width: "158", align: "center" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.reception_List[0]["reserve_system_amount_payable"]
                      )
                    ),
                  ]),
                ]),
                _c("tr", [
                  _vm._m(6),
                  _c("td", { attrs: { width: "158", align: "center" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_amount_practice"
                          ]
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("tr", [
                  _vm._m(7),
                  _c("td", { attrs: { width: "158", align: "center" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.reception_List[0]["reserve_system_amount_owed"]
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2", width: "350" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.unitList[0]["reserve_system_unit_name"])
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "地址：" +
                          _vm._s(_vm.unitList[0]["reserve_system_unit_tax"])
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "電話：" +
                          _vm._s(_vm.unitList[0]["reserve_system_unit_phone"])
                      ),
                    ]),
                    _c("p", [_vm._v("收訖章：")]),
                  ]),
                  _c("td", { attrs: { colspan: "2", width: "347" } }, [
                    _c("p", [_vm._v("收款資訊：")]),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.reception_List[0][
                                "reserve_system_pay_people"
                              ] == "",
                            expression:
                              "reception_List[0]['reserve_system_pay_people']==''",
                          },
                        ],
                      },
                      [_vm._v("□現金   繳款人：_______________")]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.reception_List[0][
                                "reserve_system_pay_people"
                              ] != "",
                            expression:
                              "reception_List[0]['reserve_system_pay_people']!=''",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "□現金   繳款人：" +
                            _vm._s(
                              _vm.reception_List[0]["reserve_system_pay_people"]
                            )
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.reception_List[0][
                                "reserve_system_pay_people"
                              ] == "",
                            expression:
                              "reception_List[0]['reserve_system_pay_people']==''",
                          },
                        ],
                      },
                      [_vm._v("□匯款   繳款人：_______________")]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.reception_List[0][
                                "reserve_system_pay_people"
                              ] != "",
                            expression:
                              "reception_List[0]['reserve_system_pay_people']!=''",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "□匯款   繳款人：" +
                            _vm._s(
                              _vm.reception_List[0]["reserve_system_pay_people"]
                            )
                        ),
                      ]
                    ),
                    _c("p", [_vm._v("帳號資訊：_________________")]),
                  ]),
                ]),
              ],
              2
            ),
          ]),
        ]),
        _c("div", { staticStyle: { margin: "10px 0", color: "red" } }, [
          _vm._v(" 本憑證請妥為保管，遺失數不補發 "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "3", width: "538", align: "center" } }, [
        _vm._v(" 費用項目 "),
      ]),
      _c("td", { attrs: { width: "158", align: "center" } }, [
        _vm._v(" 金額 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "3", width: "538" } }, [
      _c("strong", [_c("strong", [_vm._v("應繳金額")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "3", width: "538" } }, [
      _c("strong", [_c("strong", [_vm._v("實繳金額")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "3", width: "538" } }, [
      _c("strong", [_c("strong", [_vm._v("尚欠金額")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "3", width: "538", align: "center" } }, [
        _vm._v(" 費用項目 "),
      ]),
      _c("td", { attrs: { width: "158", align: "center" } }, [
        _vm._v(" 金額 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "3", width: "538" } }, [
      _c("strong", [_c("strong", [_vm._v("應繳金額")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "3", width: "538" } }, [
      _c("strong", [_c("strong", [_vm._v("實繳金額")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "3", width: "538" } }, [
      _c("strong", [_c("strong", [_vm._v("尚欠金額")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }