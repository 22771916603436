var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _vm.partner_name == null
                    ? _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: { src: _vm.logo },
                      })
                    : _c("h1", { staticClass: "sidebar-title" }, [
                        _vm._v(_vm._s(_vm.partner_name)),
                      ]),
                ]
              )
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _vm.partner_name == null
                    ? _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: { src: _vm.logo },
                      })
                    : _vm._e(),
                  _vm.partner_name == null
                    ? _c("h1", { staticClass: "sidebar-title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ])
                    : _vm._e(),
                  _vm.partner_name != null && _vm.partner_avatar != null
                    ? _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: { src: _vm.upload_path + _vm.partner_avatar },
                      })
                    : _vm._e(),
                  _vm.partner_name != null
                    ? _c("h1", { staticClass: "sidebar-title" }, [
                        _vm._v(_vm._s(_vm.partner_name)),
                      ])
                    : _vm._e(),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }