var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "components-container" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "editor-container" },
        [
          _c("el-tag", { staticClass: "tag-title" }, [_vm._v(" Basic: ")]),
          _c("markdown-editor", {
            attrs: { height: "300px" },
            model: {
              value: _vm.content1,
              callback: function ($$v) {
                _vm.content1 = $$v
              },
              expression: "content1",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "editor-container" },
        [
          _c("el-tag", { staticClass: "tag-title" }, [
            _vm._v(" Markdown Mode: "),
          ]),
          _c("markdown-editor", {
            ref: "markdownEditor",
            attrs: {
              options: { hideModeSwitch: true, previewStyle: "tab" },
              height: "200px",
            },
            model: {
              value: _vm.content2,
              callback: function ($$v) {
                _vm.content2 = $$v
              },
              expression: "content2",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "editor-container" },
        [
          _c("el-tag", { staticClass: "tag-title" }, [
            _vm._v(" Customize Toolbar: "),
          ]),
          _c("markdown-editor", {
            attrs: { options: { toolbarItems: ["heading", "bold", "italic"] } },
            model: {
              value: _vm.content3,
              callback: function ($$v) {
                _vm.content3 = $$v
              },
              expression: "content3",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "editor-container" },
        [
          _c("el-tag", { staticClass: "tag-title" }, [_vm._v(" I18n: ")]),
          _c("el-alert", {
            attrs: {
              closable: false,
              title:
                "You can change the language of the admin system to see the effect",
              type: "success",
            },
          }),
          _c("markdown-editor", {
            ref: "markdownEditor",
            attrs: { language: _vm.language, height: "300px" },
            model: {
              value: _vm.content4,
              callback: function ($$v) {
                _vm.content4 = $$v
              },
              expression: "content4",
            },
          }),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "80px" },
          attrs: { type: "primary", icon: "el-icon-document" },
          on: { click: _vm.getHtml },
        },
        [_vm._v(" Get HTML ")]
      ),
      _c("div", { domProps: { innerHTML: _vm._s(_vm.html) } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _vm._v("Markdown is based on "),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/nhnent/tui.editor",
            target: "_blank",
          },
        },
        [_vm._v("tui.editor")]
      ),
      _vm._v(" ，simply wrapped with Vue. "),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://panjiachen.github.io/vue-element-admin-site/feature/component/markdown-editor.html",
          },
        },
        [_vm._v(" Documentation ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }