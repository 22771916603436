<template>
  <div class="drawer-container">
    <div>
      <h3 class="drawer-title">偏好設定</h3>

      <div class="drawer-item">
        <span>偏好顏色</span>
        <theme-picker style="float: right;height: 26px;margin: -3px 8px 0 0;" @change="themeChange" />
      </div>

      <!-- <div class="drawer-item">
        <span>顯示偏好設定按鈕</span>
        <el-switch v-model="showSettings" class="drawer-switch" />
      </div> -->
      <div class="drawer-item">
        <span>顯示上方tab標籤</span>
        <el-switch v-model="tagsView" class="drawer-switch" />
      </div>

      <div class="drawer-item">
        <span>顯示固定表頭</span>
        <el-switch v-model="fixedHeader" class="drawer-switch" />
      </div>

      <div class="drawer-item">
        <span>顯示logo</span>
        <el-switch v-model="sidebarLogo" class="drawer-switch" />
      </div>

    </div>
  </div>
</template>

<script>
import ThemePicker from '@/components/ThemePicker'

export default {
  components: { ThemePicker },
  data() {
    return {}
  },
  computed: {
    theme: {
      get() {
        return this.$store.state.settings.theme
      }
    },
    showSettings:{
      get() {
        if(this.$store.state.settings.showSettings == 0){
          return false
        }
        return true
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
        // 改變初始設定顯示偏好設定按鈕
        this.$store.dispatch('settings/update_personal_settings', {
          key: 'showSettings',
          value: val
        })
      }
    },
    fixedHeader: {
      get() {
        if(this.$store.state.settings.fixedHeader == 0){
          return false
        }
        return true
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'fixedHeader',
          value: val
        })
        // 改變初始設定固定表頭
        this.$store.dispatch('settings/update_personal_settings', {
          key: 'fixedHeader',
          value: val
        })
      }
    },
    tagsView: {
      get() {
        if(this.$store.state.settings.tagsView == 0){
          return false
        }
        return true
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'tagsView',
          value: val
        })
        // 改變初始設定tab標籤
        this.$store.dispatch('settings/update_personal_settings', {
          key: 'tagsView',
          value: val
        })
      }
    },
    sidebarLogo: {
      get() {
        if(this.$store.state.settings.sidebarLogo == 0){
          return false
        }
        return true
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'sidebarLogo',
          value: val
        })
        // 改變初始設定logo顯示
        this.$store.dispatch('settings/update_personal_settings', {
          key: 'sidebarLogo',
          value: val
        })
      }
    }
  },
  methods: {
    themeChange(val) {
      console.log(val)
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: val
      })
      // 改變初始設定顏色
      this.$store.dispatch('settings/update_personal_settings', {
          key: 'theme',
          value: val
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-container {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;

  .drawer-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 22px;
  }

  .drawer-item {
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    padding: 12px 0;
  }

  .drawer-switch {
    float: right
  }
}
</style>
