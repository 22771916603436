<template>
  <div class="app-container">
    <div v-if="user">
      <el-row :gutter="20">

        <el-col :span="6" :xs="24">
          <user-card :user="user" v-on:getUser="getUser" />
        </el-col>
        <!-- {{user}} -->
        <el-col :span="18" :xs="24">
          <el-card>
            <el-tabs v-model="activeTab">
              <!-- <el-tab-pane label="登入紀錄" name="activity">
                <activity />
              </el-tab-pane> -->
              <el-tab-pane label="個人資料設定" name="account">
                <account :user="user" />
              </el-tab-pane>
              <el-tab-pane label="登入紀錄" name="loginrecord">
                <LoginRecord :user="user" />
              </el-tab-pane>
              <!-- <el-tab-pane label="操作紀錄" name="timeline">
                <timeline />
              </el-tab-pane> -->
              <!-- <el-tab-pane label="偏好設定" name="timeline">
                <timeline />
              </el-tab-pane> -->
              <!-- <el-tab-pane label="通知" name="notifyrecord">
                <NotifyRecord :user="user" />
              </el-tab-pane> -->
              <el-tab-pane label="操作記錄" name="logrecord">
                <LogRecord :user="user" />
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>

      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserCard from './components/UserCard'
import Activity from './components/Activity'
import LoginRecord from './components/LoginRecord'
import Timeline from './components/Timeline'
import Account from './components/Account'
import NotifyRecord from './components/NotifyRecord'
import LogRecord from './components/LogRecord'

export default {
  name: 'Profile',
  components: { UserCard, Activity, Timeline, Account,LoginRecord, NotifyRecord, LogRecord },
  data() {
    return {
      user: {},
      activeTab: 'account'
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'email',
      'avatar',
      'roles',
      'avatar',
      'phone',
      'introduction',
      'partner_id',
      'id',
      'validator_mode',
      'validator_mode_status',
      'username',
      'userstatus',
      'partner_avatar',
      'partner_name',
      'push_channel_token',
      'special_day'
    ])
  },
  async mounted(){
    await this.$store.dispatch('user/getInfo')
    await this.getUser()
  },
  methods: {
    getUser() {
      this.user = {
        id: this.id,
        name: this.name,
        email:this.email,
        // role: this.roles.join(' | '),
        username: this.username,
        roles: this.roles,
        introduction: this.introduction,
        phone: this.phone,
        avatar: this.avatar,
        partner_id: this.partner_id,
        partner_avatar: this.partner_avatar,
        partner_name: this.partner_name,
        validator_mode: this.validator_mode,
        validator_mode_status: this.validator_mode_status,
        status: this.userstatus,
        push_channel_token: this.push_channel_token,
        special_day: this.special_day
      }
      console.log("this.user",this.user)
    },
    chosen_notify_chat() {
      location.href =
        "https://notify-bot.line.me/oauth/authorize?" +
        "response_type=code" +
        "&client_id=WoKqpbFHGYjY3FcPykrId1" +
        "&redirect_uri="+window.location.href.split('?')[0] +
        "&scope=notify" +
        "&state=abcd";  // 自定義String防止CSRF攻擊
    },
  },
  beforeRouteLeave (to, from, next) {
    // ...
    // 當二次驗證開啟及二次驗證為line notify時，判定使用者未綁定line_notify通知無法跳離此頁
    if(this.user.validator_mode_status==1&&this.user.validator_mode==2&&this.push_channel_token=='') {
      this.$confirm('無綁定line推播通知，無法離開此頁', {
        confirmButtonText: '進行綁定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        this.chosen_notify_chat()
      })
    }
    else{
      next()
    }
  }
}
</script>
