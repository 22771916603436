var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "main-article",
      attrs: { "element-loading-text": "正在產生學員報告pdf" },
    },
    [
      _c("div", { staticClass: "article__heading" }, [
        _c("div", { staticClass: "article__heading__title" }, [
          _vm._v(" " + _vm._s(_vm.student_name) + " 學員報告 "),
        ]),
        _c(
          "div",
          {
            staticClass: "article__heading__title",
            staticStyle: { float: "right", "font-size": "20px" },
          },
          [_vm._v(" 報告產生日期： " + _vm._s(_vm.report_date) + " ")]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "article__reporticon__title",
          staticStyle: { float: "right", "font-size": "20px" },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-download" },
              on: { click: _vm.fetchData },
            },
            [_vm._v("匯出 " + _vm._s(_vm.student_name) + " 學員報告")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "article__healthbasic__title" }, [
        _vm._v(" 學員基本資料 "),
      ]),
      _c(
        "div",
        { staticClass: "user-profile" },
        [
          _c(
            "el-form",
            {
              ref: "HealthInfo",
              attrs: {
                "status-icon": "",
                model: _vm.HealthInfo,
                "label-position": "left",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "學員姓名", prop: "name" } },
                        [_vm._v(" " + _vm._s(_vm.HealthInfo.name) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "學員性別", prop: "sex" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("sex")(_vm.HealthInfo.sex)) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 8, lg: 6, xl: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "學員生日", prop: "birthday" } },
                        [_vm._v(" " + _vm._s(_vm.HealthInfo.birthday) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 4, lg: 6, xl: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "學員身高", prop: "height" } },
                        [_vm._v(" " + _vm._s(_vm.HealthInfo.height) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 8, lg: 8, xl: 8 } },
                    [
                      _vm.HealthInfo.H_Telephone != null
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "家裡電話", prop: "H_Telephone" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.HealthInfo.H_Telephone) + " "
                              ),
                            ]
                          )
                        : _c(
                            "el-form-item",
                            {
                              attrs: { label: "家裡電話", prop: "H_Telephone" },
                            },
                            [_vm._v(" 無填寫 ")]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 8, lg: 8, xl: 8 } },
                    [
                      _vm.HealthInfo.contact_time != null
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "聯絡時間",
                                prop: "contact_time",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.HealthInfo.contact_time) + " "
                              ),
                            ]
                          )
                        : _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "聯絡時間",
                                prop: "contact_time",
                              },
                            },
                            [_vm._v(" 無填寫 ")]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 8, lg: 8, xl: 8 } },
                    [
                      _vm.HealthInfo.O_Telephone != null
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "公司電話", prop: "O_Telephone" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.HealthInfo.O_Telephone) + " "
                              ),
                            ]
                          )
                        : _c(
                            "el-form-item",
                            {
                              attrs: { label: "公司電話", prop: "O_Telephone" },
                            },
                            [_vm._v(" 無填寫 ")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 8, lg: 8, xl: 8 } },
                    [
                      _vm.HealthInfo.FAX != null
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "傳真電話", prop: "FAX" } },
                            [_vm._v(" " + _vm._s(_vm.HealthInfo.FAX) + " ")]
                          )
                        : _c(
                            "el-form-item",
                            { attrs: { label: "傳真電話", prop: "FAX" } },
                            [_vm._v(" 無填寫 ")]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 8, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "手機", prop: "Mobile" } },
                        [_vm._v(" " + _vm._s(_vm.HealthInfo.Mobile) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 4, lg: 4, xl: 4 } },
                    [
                      _vm.HealthInfo.constellation != null
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "星座", prop: "constellation" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.HealthInfo.constellation) + " "
                              ),
                            ]
                          )
                        : _c(
                            "el-form-item",
                            { attrs: { label: "星座", prop: "constellation" } },
                            [_vm._v(" 無填寫 ")]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 4, lg: 4, xl: 4 } },
                    [
                      _vm.HealthInfo.blood != ""
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "血型", prop: "blood" } },
                            [_vm._v(" " + _vm._s(_vm.HealthInfo.blood) + " ")]
                          )
                        : _c(
                            "el-form-item",
                            { attrs: { label: "血型", prop: "blood" } },
                            [_vm._v(" 無填寫 ")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 8, lg: 8, xl: 8 } },
                    [
                      _vm.HealthInfo.spouse_name != ""
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "配偶姓名", prop: "spouse_name" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.HealthInfo.spouse_name) + " "
                              ),
                            ]
                          )
                        : _c(
                            "el-form-item",
                            {
                              attrs: { label: "配偶姓名", prop: "spouse_name" },
                            },
                            [_vm._v(" 無填寫 ")]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 8, lg: 8, xl: 8 } },
                    [
                      _vm.HealthInfo.anniversary != null
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "結婚紀念日",
                                prop: "anniversary",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.HealthInfo.anniversary) + " "
                              ),
                            ]
                          )
                        : _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "結婚紀念日",
                                prop: "anniversary",
                              },
                            },
                            [_vm._v(" 無填寫 ")]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 8, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "課程狀態", prop: "class_level" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("class_level")(
                                  _vm.HealthInfo.class_level
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "article__healthbasic__title" }, [
        _vm._v(" 學員所有量測數據 "),
      ]),
      _c(
        "div",
        { staticClass: "user_profile" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.HealthMeatureInfo, function (item, index) {
              return _c(
                "el-form",
                {
                  key: index,
                  ref: "HealthMeatureInfo",
                  refInFor: true,
                  attrs: { "status-icon": "", "label-position": "left" },
                },
                [
                  _c("el-divider", [_vm._v("量測數據開始")]),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "量測時間" } }, [
                            _vm._v(" " + _vm._s(item.measuretime) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "體重" } }, [
                            _vm._v(" " + _vm._s(item.weight) + " kg "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "體脂" } }, [
                            _vm._v(" " + _vm._s(item.bodyfat) + " % "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "肌肉量" } }, [
                            _vm._v(" " + _vm._s(item.muscle) + " kg "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "內脂" } }, [
                            _vm._v(" " + _vm._s(item.lactose) + " % "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "基礎代謝" } }, [
                            _vm._v(" " + _vm._s(item.BMR) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "體齡" } }, [
                            _vm._v(" " + _vm._s(item.bodyfat) + " 歲 "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "骨量" } }, [
                            _vm._v(" " + _vm._s(item.mass) + " % "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "胸圍（腋下）" } },
                            [_vm._v(" " + _vm._s(item.chest_underarm) + " cm ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "胸圍（乳頭）" } },
                            [_vm._v(" " + _vm._s(item.chest_nipples) + " cm ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "胃圍" } }, [
                            _vm._v(" " + _vm._s(item.stomach) + " cm "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "肚臍下三指" } },
                            [_vm._v(" " + _vm._s(item.navel_down) + " cm ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "肚臍上三指" } },
                            [_vm._v(" " + _vm._s(item.navel_up) + " cm ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "臀圍" } }, [
                            _vm._v(" " + _vm._s(item.hip) + " cm "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "大腿（右）" } },
                            [_vm._v(" " + _vm._s(item.thigh_r) + " cm ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "大腿（左）" } },
                            [_vm._v(" " + _vm._s(item.thigh_l) + " cm ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "小腿（右）" } },
                            [_vm._v(" " + _vm._s(item.calf_r) + " cm ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "小腿（左）" } },
                            [_vm._v(" " + _vm._s(item.calf_l) + " cm ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "手臂（右）" } },
                            [_vm._v(" " + _vm._s(item.arm_r) + " cm ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "手臂（左）" } },
                            [_vm._v(" " + _vm._s(item.arm_l) + " cm ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12, lg: 12, xl: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "學員回饋" } }, [
                            _vm._v(" " + _vm._s(item.member_feedback) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12, lg: 12, xl: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "教練回饋" } }, [
                            _vm._v(" " + _vm._s(item.coach_feedback) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-divider", [_vm._v("量測數據結束")]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "article__healthbasic__title" }, [
        _vm._v(" 學員使用前後照片 "),
      ]),
      _c(
        "div",
        { staticClass: "user_profile" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.HealthPhotoInfo, function (item, index) {
              return _c(
                "el-form",
                {
                  key: index,
                  ref: "HealthMeatureInfo",
                  refInFor: true,
                  attrs: { "status-icon": "", "label-position": "left" },
                },
                [
                  _c("el-divider", [_vm._v("圖片開始")]),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "圖片標記" } }, [
                            _vm._v(" " + _vm._s(item.note_name) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "圖片" } },
                            [
                              _c(
                                "el-image",
                                {
                                  staticStyle: { "max-width": "350px" },
                                  attrs: {
                                    src: _vm.upload_path + item.upload_image,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "備註內容" } }, [
                            _vm._v(" " + _vm._s(item.mark) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "建立日期" } }, [
                            _vm._v(" " + _vm._s(item.createtime) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-divider", [_vm._v("圖片結束")]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }