var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    [
      _c(
        "el-form-item",
        { attrs: { label: "合作夥伴名稱" } },
        [
          _vm.user.partner_name == null
            ? _c(
                "el-tag",
                { attrs: { type: "danger", size: "medium", effect: "plain" } },
                [_vm._v("無")]
              )
            : _c(
                "el-tag",
                { attrs: { type: "success", size: "medium", effect: "plain" } },
                [_vm._v(_vm._s(_vm.user.partner_name))]
              ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "個人頭貼" } },
        [
          _c("pan-thumb", { attrs: { image: _vm.avatar } }),
          _c(
            "div",
            { staticStyle: { margin: "50px 0" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    position: "absolute",
                    bottom: "5px",
                    "margin-left": "7%",
                  },
                  attrs: { type: "primary", icon: "el-icon-upload" },
                  on: {
                    click: function ($event) {
                      _vm.imagecropperavatarShow = true
                    },
                  },
                },
                [_vm._v(" 個人頭貼 ")]
              ),
              _c(
                "el-tag",
                {
                  staticStyle: {
                    position: "absolute",
                    bottom: "-30px",
                    "margin-left": "6%",
                  },
                  attrs: { type: "danger", effect: "plain" },
                },
                [_vm._v("請注意大小最好為正方形")]
              ),
            ],
            1
          ),
          _c("image-cropper", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.imagecropperavatarShow,
                expression: "imagecropperavatarShow",
              },
            ],
            key: _vm.imagecropperavatarKey,
            attrs: {
              width: 150,
              height: 150,
              url: _vm.upload_avatar_path,
              "lang-type": "zh-tw",
              field: "avatar",
            },
            on: {
              close: _vm.updateavatarclose,
              "crop-upload-success": _vm.updateavatarlogo,
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "姓名" } },
        [
          _c("el-input", {
            model: {
              value: _vm.user.name,
              callback: function ($$v) {
                _vm.$set(
                  _vm.user,
                  "name",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "user.name",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "電話" } },
        [
          _c("el-input", {
            model: {
              value: _vm.user.phone,
              callback: function ($$v) {
                _vm.$set(
                  _vm.user,
                  "phone",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "user.phone",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "信箱" } },
        [
          _c("el-input", {
            model: {
              value: _vm.user.email,
              callback: function ($$v) {
                _vm.$set(
                  _vm.user,
                  "email",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "user.email",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "簡介" } },
        [
          _c("el-input", {
            model: {
              value: _vm.user.introduction,
              callback: function ($$v) {
                _vm.$set(
                  _vm.user,
                  "introduction",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "user.introduction",
            },
          }),
        ],
        1
      ),
      _c("el-form-item", { attrs: { label: "特休" } }, [
        _vm._v(" " + _vm._s(_vm.user.special_day) + " 天 "),
      ]),
      _c(
        "el-form-item",
        { attrs: { label: "角色" } },
        [
          _c(
            "el-select",
            {
              ref: "roles",
              attrs: {
                name: "roles",
                filterable: "",
                "allow-create": "",
                "default-first-option": "",
                clearable: "",
                placeholder: "選擇角色",
                disabled: "",
              },
              model: {
                value: _vm.user.roles,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "roles", $$v)
                },
                expression: "user.roles",
              },
            },
            _vm._l(_vm.adminroleslist, function (item) {
              return _c("el-option", {
                key: item.roles_id,
                attrs: { label: item.roles_name, value: item.roles_id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "使用者狀態" } },
        [
          _vm.user.status == 0
            ? _c(
                "el-tag",
                { attrs: { type: "danger", size: "medium", effect: "plain" } },
                [_vm._v("停權")]
              )
            : _vm._e(),
          _vm.user.status == 1
            ? _c(
                "el-tag",
                { attrs: { type: "success", size: "medium", effect: "plain" } },
                [_vm._v("啟用")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "二次驗證開關", prop: "validator_mode_status" } },
        [
          _c(
            "el-radio-group",
            {
              ref: "validator_mode_status",
              attrs: { name: "validator_mode_status" },
              model: {
                value: _vm.user.validator_mode_status,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "validator_mode_status", $$v)
                },
                expression: "user.validator_mode_status",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "0", border: "" } }, [
                _vm._v("關閉"),
              ]),
              _c("el-radio-button", { attrs: { label: "1", border: "" } }, [
                _vm._v("開啟"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "二次驗證模式", prop: "validator_mode" } },
        [
          _c(
            "el-radio-group",
            {
              ref: "validator_mode",
              attrs: { name: "validator_mode" },
              model: {
                value: _vm.user.validator_mode,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "validator_mode", $$v)
                },
                expression: "user.validator_mode",
              },
            },
            [
              _c(
                "el-radio-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.user.validator_mode_status == 0,
                      expression: "user.validator_mode_status==0",
                    },
                  ],
                  attrs: { label: "0", border: "" },
                },
                [_vm._v("無")]
              ),
              _c(
                "el-radio-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.user.validator_mode_status == 1,
                      expression: "user.validator_mode_status==1",
                    },
                  ],
                  attrs: { label: "1", border: "" },
                },
                [_vm._v("信箱")]
              ),
              _c(
                "el-radio-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.user.validator_mode_status == 1,
                      expression: "user.validator_mode_status==1",
                    },
                  ],
                  attrs: { label: "2", border: "" },
                },
                [_vm._v("line notify")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.user.validator_mode_status == 1 &&
                _vm.user.validator_mode == 2,
              expression:
                "user.validator_mode_status==1&&user.validator_mode==2",
            },
          ],
          attrs: { label: "line通知狀態" },
        },
        [
          _vm.push_channel_token == ""
            ? _c(
                "el-tag",
                {
                  attrs: { type: "primary", size: "medium", effect: "plain" },
                  on: { click: _vm.chosen_notify_chat },
                },
                [_vm._v("開啟綁定")]
              )
            : _c(
                "el-tag",
                {
                  attrs: { type: "danger", size: "medium", effect: "plain" },
                  on: { click: _vm.confirm_revoke_notify_chat },
                },
                [_vm._v("關閉綁定")]
              ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.push_channel_token != "" &&
                _vm.user.validator_mode_status == 1 &&
                _vm.user.validator_mode == 2,
              expression:
                "push_channel_token!=''&&user.validator_mode_status==1&&user.validator_mode==2",
            },
          ],
          attrs: { label: "line通知token" },
        },
        [_vm._v(" " + _vm._s(_vm.push_channel_token) + " ")]
      ),
      _vm.user.roles == 1 && _vm.user.partner_id != null
        ? _c(
            "el-form-item",
            { attrs: { label: "設定合作夥伴logo預覽（適用後端上傳）" } },
            [
              _c("pan-thumb", { attrs: { image: _vm.partner_avatar } }),
              _c(
                "div",
                { staticStyle: { margin: "50px 0" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        position: "absolute",
                        bottom: "15px",
                        "margin-left": "40px",
                      },
                      attrs: { type: "primary", icon: "el-icon-upload" },
                      on: {
                        click: function ($event) {
                          _vm.imagecropperpartnerShow = true
                        },
                      },
                    },
                    [_vm._v(" 設定合作夥伴logo（適用後端上傳） ")]
                  ),
                ],
                1
              ),
              _c("image-cropper", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.imagecropperpartnerShow,
                    expression: "imagecropperpartnerShow",
                  },
                ],
                key: _vm.imagecropperpartnerKey,
                attrs: {
                  width: 200,
                  height: 200,
                  url: _vm.upload_path,
                  "lang-type": "zh-tw",
                  field: "partner_avatar",
                },
                on: {
                  close: _vm.updatepartnerclose,
                  "crop-upload-success": _vm.updatepartnerlogo,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.updateaccount } },
            [_vm._v("更新")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }