var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleAddRole } },
        [_vm._v("New Role")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: { data: _vm.rolesList, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "Role Key", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.key) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Role Name", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.name) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "header-center", label: "Description" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.description) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Operations" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope)
                          },
                        },
                      },
                      [_vm._v("Edit")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope)
                          },
                        },
                      },
                      [_vm._v("Delete")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: _vm.dialogType === "edit" ? "Edit Role" : "New Role",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.role,
                "label-width": "80px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Role Name" },
                    model: {
                      value: _vm.role.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.role, "name", $$v)
                      },
                      expression: "role.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Desc" } },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 2, maxRows: 4 },
                      type: "textarea",
                      placeholder: "Role Description",
                    },
                    model: {
                      value: _vm.role.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.role, "description", $$v)
                      },
                      expression: "role.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Menus" } },
                [
                  _c("el-tree", {
                    ref: "tree",
                    staticClass: "permission-tree",
                    attrs: {
                      "check-strictly": _vm.checkStrictly,
                      data: _vm.routesData,
                      props: _vm.defaultProps,
                      "show-checkbox": "",
                      "node-key": "path",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmRole } },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }