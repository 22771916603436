var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.id))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "180px", align: "center", label: "Date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            row.timestamp,
                            "{y}-{m}-{d} {h}:{i}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "120px", align: "center", label: "Author" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.author))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "100px", label: "Importance" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(+row.importance, function (n) {
                    return _c("svg-icon", {
                      key: n,
                      staticClass: "meta-item__icon",
                      attrs: { "icon-class": "star" },
                    })
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "status-col",
              label: "Status",
              width: "110",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: _vm._f("statusFilter")(row.status) } },
                      [_vm._v(" " + _vm._s(row.status) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "300px", label: "Title" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.edit
                      ? [
                          _c("el-input", {
                            staticClass: "edit-input",
                            attrs: { size: "small" },
                            model: {
                              value: row.title,
                              callback: function ($$v) {
                                _vm.$set(row, "title", $$v)
                              },
                              expression: "row.title",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              staticClass: "cancel-btn",
                              attrs: {
                                size: "small",
                                icon: "el-icon-refresh",
                                type: "warning",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.cancelEdit(row)
                                },
                              },
                            },
                            [_vm._v(" cancel ")]
                          ),
                        ]
                      : _c("span", [_vm._v(_vm._s(row.title))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Actions", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.edit
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              size: "small",
                              icon: "el-icon-circle-check-outline",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.confirmEdit(row)
                              },
                            },
                          },
                          [_vm._v(" Ok ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon: "el-icon-edit",
                            },
                            on: {
                              click: function ($event) {
                                row.edit = !row.edit
                              },
                            },
                          },
                          [_vm._v(" Edit ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }