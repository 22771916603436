var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "board-column" },
    [
      _c("div", { staticClass: "board-column-header" }, [
        _vm._v(" " + _vm._s(_vm.headerText) + " "),
      ]),
      _c(
        "draggable",
        _vm._b(
          {
            staticClass: "board-column-content",
            attrs: { list: _vm.list, "set-data": _vm.setData },
          },
          "draggable",
          _vm.$attrs,
          false
        ),
        _vm._l(_vm.list, function (element) {
          return _c("div", { key: element.id, staticClass: "board-item" }, [
            _vm._v(" " + _vm._s(element.name) + " " + _vm._s(element.id) + " "),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }