var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "components-container" },
    [
      _c("aside", [
        _vm._v(
          " When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner "
        ),
      ]),
      _c("aside", [
        _vm._v(
          " You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally "
        ),
      ]),
      _vm._m(0),
      _c(
        "el-tooltip",
        { attrs: { placement: "top", content: "tooltip" } },
        [
          _c("back-to-top", {
            attrs: {
              "custom-style": _vm.myBackToTopStyle,
              "visibility-height": 300,
              "back-position": 50,
              "transition-name": "fade",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "placeholder-container" }, [
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
      _c("div", [_vm._v("placeholder")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }