var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        {
          staticClass: "github-corner",
          attrs: { trigger: "click" },
          on: { command: _vm.handleclickannounce },
        },
        [
          _c("span", { staticClass: "el-dropdown-link" }, [
            _vm._v(" 公告訊息"),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "el-dropdown-item",
                { attrs: { command: "first", icon: "el-icon-news" } },
                [_vm._v("此次公告")]
              ),
              _c(
                "el-dropdown-item",
                { attrs: { command: "second", icon: "el-icon-news" } },
                [_vm._v("歷史公告")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.today_announce_list.length != 0
        ? _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.today_announce_list[0].announce_title,
                    visible: _vm.dialogVisible,
                    width: "50%",
                    center: "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c("div", { staticClass: "announce_content" }, [
                    _c("span", {
                      staticStyle: {
                        color: "blue",
                        "white-space": "pre-wrap",
                        "font-size": "16px",
                      },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.today_announce_list[0].announce_content
                        ),
                      },
                    }),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = false
                            },
                          },
                        },
                        [_vm._v("確定")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "歷史公告",
            visible: _vm.dialogVisible_past,
            width: "50%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_past = $event
            },
          },
        },
        [
          _c(
            "el-collapse",
            { attrs: { accordion: "" } },
            _vm._l(
              _vm.past_announce_list,
              function (past_announce_list, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "el-collapse-item",
                      {
                        staticClass: "announce_content",
                        attrs: {
                          title: past_announce_list.announce_title,
                          name: index,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "blue",
                              "white-space": "pre-wrap",
                              "font-size": "16px",
                            },
                          },
                          [_vm._v(_vm._s(past_announce_list.announce_content))]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }
            ),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_past = false
                    },
                  },
                },
                [_vm._v("確定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }