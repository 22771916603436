<template>
  <div class="dashboard-container">
    <!-- {{currentRole}} -->
    <!-- 所有dashboard測試範例 -->
    <!-- <component :is="currentRole" /> -->
    <!-- <component :is="currentRole" /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import adminDashboard from './admin'
// import editorDashboard from './editor'
//預約dashboard
import ReserveSystemDashboard from './reserve_system_dashboard'

export default {
  name: 'Dashboard',
  // components: { adminDashboard, editorDashboard },
  components:{ ReserveSystemDashboard },
  data() {
    return {
      currentRole: 'ReserveSystemDashboard'
    }
  },
  computed: {
    ...mapGetters([
      'roles'
    ])
    // roles(){
    //   return this.$store.getters.roles
    // }
  },
  async created() {
    await this.$store.dispatch('user/getInfo')
    // if (!this.roles.includes('admin')) {
    //   this.currentRole = 'editorDashboard'
    // }
  }
}
</script>
