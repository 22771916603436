var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "todoapp" }, [
    _vm._m(0),
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.todos.length,
            expression: "todos.length",
          },
        ],
        staticClass: "main",
      },
      [
        _c("label", { attrs: { for: "toggle-all" } }),
        _vm._l(_vm.todolist.list, function (todo, index) {
          return _c("ul", { key: index, staticClass: "todo-list" }, [
            todo.schedule_status == 0
              ? _c("li", [
                  _c(
                    "div",
                    { staticStyle: { "font-size": "30px", margin: "10px" } },
                    [
                      _c("el-tag", { staticStyle: { "margin-right": "5px" } }, [
                        _vm._v(_vm._s(todo.partner_name)),
                      ]),
                      _c(
                        "el-tag",
                        {
                          attrs: {
                            type: todo.schedule_status == 0 ? "danger" : "",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("schedule_status")(todo.schedule_status)
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "20px",
                          margin: "5px",
                          color: "black",
                        },
                      },
                      [_vm._v("標題：" + _vm._s(todo.schedule_title))]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "15px",
                          margin: "5px",
                          color: "red",
                        },
                      },
                      [_vm._v("內文：" + _vm._s(todo.schedule_note))]
                    ),
                  ]),
                ])
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header" }, [
      _c("input", {
        staticClass: "new-todo",
        attrs: { autocomplete: "off", placeholder: "所有代辦事項" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }