var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticStyle: { margin: "0 0 5px 20px" } }, [
        _vm._v(" Fixed header, sorted by header order, "),
      ]),
      _c("fixed-thead"),
      _c("div", { staticStyle: { margin: "30px 0 5px 20px" } }, [
        _vm._v(" Not fixed header, sorted by click order "),
      ]),
      _c("unfixed-thead"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }