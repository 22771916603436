var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm._m(0),
      _c(
        "el-button",
        {
          attrs: { icon: "el-icon-question", type: "primary" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.guide.apply(null, arguments)
            },
          },
        },
        [_vm._v(" Show Guide ")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _vm._v(
        " The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/kamranahmedse/driver.js",
            target: "_blank",
          },
        },
        [_vm._v("driver.js.")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }