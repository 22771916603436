<template>
  <div class="dashboard-editor-container" 
  v-loading="ReservationSystemDashboardLoading"
  v-if='this.check_admin_premission.includes("insert_btn")||this.check_admin_premission.includes("search_btn")||this.check_admin_premission.includes("update_btn")'>
    <!-- <github-corner class="github-corner" /> -->
    <!-- {{select_start_date}}
    {{select_end_date}}
    {{select_range_time}} -->
    <!-- {{get_practice_reserve_date}} -->
    <!-- {{lineChartData}} -->
    <el-row :gutter="20">
        <el-col :xs="24" :sm="6" :lg="6" :xl="6">
            <el-select
            v-model="partner"
            placeholder="請選擇合作夥伴"
            filterable
            default-first-option
            style="margin:5px;"
            @change="chosenPartner"
            >
            <el-option
                v-for="item in partnerlist.list"
                :key="item.partner_id"
                :label="item.partner_name"
                :value="item.partner_id"
            />
            </el-select>
        </el-col>
        <el-col :xs="24" :sm="18" :lg="18" :xl="18" align="right">
            <el-radio style="margin:0px 5px; background:white;" v-model="select_range_time" :label="0" border>日</el-radio>
            <el-radio style="margin:0px 5px; background:white;" v-model="select_range_time" :label="1" border>週</el-radio>
            <el-radio style="margin:0px 5px; background:white;" v-model="select_range_time" :label="2" border>月</el-radio>
            <el-radio style="margin:0px 5px; background:white;" v-model="select_range_time" :label="3" border>年</el-radio>
            <el-button type="primary" icon="el-icon-d-arrow-left" style="margin:0px 10px;" @click="select_last_date"></el-button>
            <el-tag type="primary" style="margin:10px; font-size:20px;">{{search_period_date}}</el-tag>
            <el-button type="primary" icon="el-icon-d-arrow-right" style="margin:0px 10px;" @click="select_next_date"></el-button>
        </el-col>
    </el-row>
    <!-- <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :lg="24" :xl="24" align="center">
          <el-button type="primary" icon="el-icon-d-arrow-left" style="margin:0px 10px;" @click="select_last_date"></el-button>
          <el-tag type="primary" style="margin:10px; font-size:20px;">{{search_period_date}}</el-tag>
          <el-button type="primary" icon="el-icon-d-arrow-right" style="margin:0px 10px;" @click="select_next_date"></el-button>
        </el-col>
    </el-row> -->
    <announce-list/>
    <!-- {{lineChartData}} -->
    <panel-group 
    @handleSetLineChartData="handleSetLineChartData" 
    :appoint_reserve="appoint_reserve" 
    :appoint_earn="appoint_earn"
    :appoint_member="appoint_member"
    :select_range_time="select_range_time"
    />

    <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
      <line-chart 
      :chart-data="lineChartData"
      :select_line_type="select_line_type"
      :get_practice_reserve_date="get_practice_reserve_date" />
    </el-row>

    <el-row :gutter="20">
      <!-- <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <raddar-chart />
        </div>
      </el-col> -->
      <el-col :xs="24" :sm="12" :lg="12" :xl="12">
        <div class="chart-wrapper" style="text-align:center;">
          <p style="margin:15px 0; font-size:20px;">熱銷程度排行</p>
        <el-radio style="margin:5px; background:white;" v-model="select_class_range" :label="0" border>課程</el-radio>
        <el-radio style="margin:5px; background:white;" v-model="select_class_range" :label="1" border>治療師</el-radio>
          <pie-chart
          :partner="partner"
          :search_period_date="search_period_date"
          :select_class_range="select_class_range"
          :select_start_date="select_start_date"
          :select_end_date="select_end_date"
          />
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :lg="12" :xl="12">
        <div class="chart-wrapper" style="text-align:center;">
           <p style="margin:15px 0; font-size:20px;">即將來到預約資訊</p>
        <el-radio style="margin:5px; background:white;" v-model="select_class_range" :label="0" border>課程</el-radio>
        <el-radio style="margin:5px; background:white;" v-model="select_class_range" :label="1" border>治療師</el-radio>
          <transaction-table 
          :select_class_range="select_class_range" 
          :get_now_reserve_type_0="get_now_reserve_type_0"
          :get_now_reserve_type_1="get_now_reserve_type_1"
          />
        </div>
      </el-col>
      <!-- <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <bar-chart />
        </div>
      </el-col> -->
    </el-row>

    <!-- <el-row :gutter="8">
      <el-col :xs="{span: 24}" :sm="{span: 24}" :md="{span: 12}" :lg="{span: 18}" :xl="{span: 18}" style="padding-right:8px;margin-bottom:30px;">
        <transaction-table />
      </el-col>
      <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 12}" :lg="{span: 6}" :xl="{span: 6}" style="margin-bottom:30px;">
        <todo-list />
      </el-col>
      <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 12}" :lg="{span: 6}" :xl="{span: 6}" style="margin-bottom:30px;">
        <box-card />
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import GithubCorner from '@/components/GithubCorner'
import { getAdminPremission } from '@/api/premission_manager'
import { get_Appoint_Dashboard_1,get_Appoint_Dashboard_2 } from '@/api/reservesystemdashboard'
import PanelGroup from './components/PanelGroup'
import LineChart from './components/LineChart'
import RaddarChart from './components/RaddarChart'
import PieChart from './components/PieChart'
import BarChart from './components/BarChart'
import TransactionTable from './components/TransactionTable'
import TodoList from './components/TodoList'
import AnnounceList from './components/AnnounceList'
import BoxCard from './components/BoxCard'
import moment from 'moment'

const DefaultlineChartData = {
  shopping: {
  },
  money: {
  },
  user: {
  },
}

export default {
  name: 'DashboardAdmin',
  components: {
    // GithubCorner,
    PanelGroup,
    LineChart,
    RaddarChart,
    PieChart,
    BarChart,
    TransactionTable,
    TodoList,
    AnnounceList,
    BoxCard
  },
  computed:{
    ...mapGetters([
      'partnerlist',
      'roles',
      'partner_id'
    ])
  },
  watch:{
    "select_range_time":{
        handler: function(val, oldVal) {
          //日
          if(val==0){
            this.select_start_date = moment(new Date()).startOf('day').format('YYYY-MM-DD')
            this.select_end_date = moment(new Date()).endOf('day').format('YYYY-MM-DD')
          }
          //週
          if(val==1){
            this.select_start_date = moment(new Date()).startOf('isoWeek').format('YYYY-MM-DD')
            this.select_end_date = moment(new Date()).endOf('isoWeek').format('YYYY-MM-DD')
          }
          //月
          if(val==2){
            this.select_start_date = moment(new Date()).startOf('month').format('YYYY-MM-DD')
            this.select_end_date = moment(new Date()).endOf('month').format('YYYY-MM-DD')
          }
          //年
          if(val==3){
            this.select_start_date = moment(new Date()).startOf('year').format('YYYY-MM-DD')
            this.select_end_date = moment(new Date()).endOf('year').format('YYYY-MM-DD')
          }
          this.get_Appoint_Dashboard_1()
          this.get_Appoint_Dashboard_2()
      },
      immediate: true
      },
      "select_class_range":{
        handler: function(val, oldVal) {
          this.get_Appoint_Dashboard_2()
      },
      immediate: true
      }
  },
  async created() {
    // 顯示合作夥伴
    await this.getPartner('all')
    //預設帶入第一個partner_id
    await this.initPartner()
    // 得知使用者實際擁有權限
    await this.getAdminPremission("預約上架模組")
  },
  data() {
    return {
      lineChartData: Object.assign({}, DefaultlineChartData),
      partner: '',
      check_admin_premission:[],
      select_line_type:'shopping',
      select_range_time:1,
      select_start_date:'00-00-00',
      select_end_date:'00-00-00',
      select_class_range:0,
      //預約人數
      appoint_reserve:0,
      //盈餘
      appoint_earn:0,
      //會員
      appoint_member:0,
      //實際日期
      get_practice_reserve_date:[],
      //搜尋區間
      search_period_date:'',
      //得知即將來到課程資料
      get_now_reserve_type_0:[],
      //得知即將來到治療師資料
      get_now_reserve_type_1:[],
      ReservationSystemDashboardLoading:false
    }
  },
  methods: {
    // 得知使用者實際擁有權限
    async getAdminPremission(premission_name) {
      var payload = {
        "check_admin_premission":premission_name
      }
        getAdminPremission(payload).then((resp)=>{
          console.log(resp)
          console.log(resp['check_admin_premission'])
          resp['check_admin_premission'].forEach((element)=>{
            this.check_admin_premission.push(element)
          })
        })
    },
    //選擇上一個
    async select_last_date(){
      //日
      if(this.select_range_time==0){
        this.select_start_date = moment(this.select_start_date).subtract(1, 'd').format('YYYY-MM-DD')
        this.select_end_date = moment(this.select_end_date).subtract(1, 'd').format('YYYY-MM-DD')
        console.log(this.select_start_date)
        console.log(this.select_end_date)
      }
      //週
      if(this.select_range_time==1){
        this.select_start_date = moment(this.select_start_date).subtract(1, 'w').format('YYYY-MM-DD')
        this.select_end_date = moment(this.select_end_date).subtract(1, 'w').format('YYYY-MM-DD')
        console.log(this.select_start_date)
        console.log(this.select_end_date)
      }
      //月
      if(this.select_range_time==2){
        this.select_start_date = moment(this.select_start_date).subtract(1, 'M').format('YYYY-MM-DD')
        this.select_end_date = moment(this.select_end_date).subtract(1, 'M').format('YYYY-MM-DD')
        console.log(this.select_start_date)
        console.log(this.select_end_date)
      }
      //年
      if(this.select_range_time==3){
        this.select_start_date = moment(this.select_start_date).subtract(1, 'y').format('YYYY-MM-DD')
        this.select_end_date = moment(this.select_end_date).subtract(1, 'y').format('YYYY-MM-DD')
        console.log(this.select_start_date)
        console.log(this.select_end_date)
      }
      await this.get_Appoint_Dashboard_1()
      await this.get_Appoint_Dashboard_2()
    },
    //選擇下一個
    async select_next_date(){
      //日
      if(this.select_range_time==0){
        this.select_start_date = moment(this.select_start_date).add(1, 'd').format('YYYY-MM-DD')
        this.select_end_date = moment(this.select_end_date).add(1, 'd').format('YYYY-MM-DD')
        console.log(this.select_start_date)
        console.log(this.select_end_date)
      }
      //週
      if(this.select_range_time==1){
        this.select_start_date = moment(this.select_start_date).add(1, 'w').format('YYYY-MM-DD')
        this.select_end_date = moment(this.select_end_date).add(1, 'w').format('YYYY-MM-DD')
        console.log(this.select_start_date)
        console.log(this.select_end_date)
      }
      //月
      if(this.select_range_time==2){
        this.select_start_date = moment(this.select_start_date).add(1, 'M').format('YYYY-MM-DD')
        this.select_end_date = moment(this.select_end_date).add(1, 'M').format('YYYY-MM-DD')
        console.log(this.select_start_date)
        console.log(this.select_end_date)
      }
      //年
      if(this.select_range_time==3){
        this.select_start_date = moment(this.select_start_date).add(1, 'y').format('YYYY-MM-DD')
        this.select_end_date = moment(this.select_end_date).add(1, 'y').format('YYYY-MM-DD')
        console.log(this.select_start_date)
        console.log(this.select_end_date)
      }
      await this.get_Appoint_Dashboard_1()
      await this.get_Appoint_Dashboard_2()
    },
    //儀表板(預約、盈餘、會員包括折線圖)
    async get_Appoint_Dashboard_1(){
      var payload = ''
      payload ='?partner_id='+this.partner+'&search_type='+this.select_range_time+
      '&search_start_date='+this.select_start_date+'&search_end_date='+this.select_end_date
      await get_Appoint_Dashboard_1(payload).then((resp)=>{
        this.appoint_reserve = resp.get_reserve_appoint_count
        this.appoint_earn = resp.get_reserve_appoint_earn_count
        this.appoint_member = resp.get_reserve_member_count
        this.lineChartData['shopping']= resp.get_practice_reserve_person
        this.lineChartData['money'] = resp.get_practice_reserve_earn
        this.lineChartData['user'] = resp.get_practice_reserve_member
        this.search_period_date = resp.search_period_date
        this.get_practice_reserve_date = resp.get_practice_reserve_date
        console.log(this.lineChartData)
      })
    },
    //儀表板(熱銷程度排名、即將來到預約)
    async get_Appoint_Dashboard_2(){
      var payload = ''
      payload ='?partner_id='+this.partner+'&search_type='+this.select_range_time+
      '&search_start_date='+this.select_start_date+'&search_end_date='+this.select_end_date
      await get_Appoint_Dashboard_2(payload).then(async (resp)=>{
        //得知即將來到課程資料
        this.get_now_reserve_type_0 = resp.get_now_reserve_type_0
        //得知即將來到治療師資料
        this.get_now_reserve_type_1 = resp.get_now_reserve_type_1
      })
    },
    async chosenPartner(){
        console.log(this.partner)
        this.ReservationSystemDashboardLoading = true
        this.get_Appoint_Dashboard_1()
        this.get_Appoint_Dashboard_2()
        this.ReservationSystemDashboardLoading = false
    },
    handleSetLineChartData(type) {
      console.log(type)
      // this.lineChartData = Object.assign({}, DefaultlineChartData)
      this.ReservationSystemDashboardLoading = true
      this.get_Appoint_Dashboard_1()
      this.lineChartData = this.lineChartData[type]
      this.select_line_type = type
      this.ReservationSystemDashboardLoading = false
    },
    // 顯示合作夥伴
    async getPartner(page) {
      var payload = ''
      payload = page+ '?' + 'partner_open_close=1'
      await this.$store.dispatch('partner/getPartner', payload)
    },
    //剛開始直接設定預設的partner
    async initPartner(){
        await this.partnerlist.list.forEach(async(element,index)=>{
          if(index==0){
            console.log("show_first_init_partner",element.partner_id)
            this.partner = element.partner_id
          }
          console.log("show_all_init_partner",element.partner_id)
          this.ReservationSystemDashboardLoading = true
          //儀表板(預約、盈餘、會員包括折線圖)
          await this.get_Appoint_Dashboard_1()
          //儀表板(熱銷程度排名、即將來到預約)
          await this.get_Appoint_Dashboard_2()
          this.ReservationSystemDashboardLoading = false
        })
        console.log(this.activeTab)
    },
  }
}
</script>

<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: rgb(240, 242, 245);
  position: relative;

  .github-corner {
    position: absolute;
    top: 10px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}

@media (max-width:1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
